<template>
  <v-progress-linear
    :value="getProgressValue()"
    :color="isCompleted ? 'success' : getProgressColor()"
    height="20"
    stream
    rounded
    reverse
  >
    <template #default>
      <strong
        v-if="isCompleted"
        class="white--text"
      >
        {{ $t('Completed') }}
      </strong>
      <strong
        v-else-if="remainDays < 0"
        class="white--text"
      >
        {{ $t('Passed due date') }}
      </strong>
      <strong
        v-else
        class="white--text"
      >
        {{ remainDays }} {{ $t('days remains') }}
      </strong>
    </template>
  </v-progress-linear>
</template>

<script>
export default {
  props: {
    remainDays: { type: Number, required: true },
    isCompleted: { type: Boolean, default: false },
  },

  methods: {
    getProgressColor() {
      if ([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].includes(this.remainDays) || this.remainDays < 0) return 'error'
      if ([11, 12, 13, 14, 15].includes(this.remainDays)) return 'warning'
      if ([16, 17, 18, 19, 20].includes(this.remainDays)) return 'blue'
    },

    getProgressValue() {
      let x = 20 - this.remainDays
      return (x / 20) * 100
    },
  },
}
</script>

<style lang="scss" scoped>
</style>