<template>
  <div>
    <v-row justify="center">
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="8"
        xl="7"
      >
        <v-card outlined>
          <v-card-text>
            <template v-if="!showDetails">
              <v-row>
                <v-col
                  v-for="form in seasonalForms"
                  :key="form.id"
                  class="text-center pointer hover rounded"
                  cols="auto"
                  @click="openFolder(form)"
                >
                  <v-icon
                    class="text-h1"
                    color="blue"
                  >
                    mdi-folder
                  </v-icon>
                  <div class="d-block">
                    {{ form.name }}
                  </div>
                </v-col>
              </v-row>
            </template>

            <template v-if="showDetails">
              <v-row class="align-center">
                <v-col cols="auto">
                  <v-btn
                    depressed
                    small
                    text
                    color="blue"
                    @click="back()"
                  >
                    <v-icon left>
                      mdi-chevron-right
                    </v-icon>
                    {{ $t('Back') }}
                  </v-btn>
                </v-col>

                <v-col cols>
                  {{ $t('Forms of year') }} {{ activeForm.name }}
                </v-col>
              </v-row>

              <v-row
                v-for="(detail, detailIndex) in formDetails"
                :key="detail.id"
                align="center"
              >
                <v-col
                  cols="12"
                  sm="6"
                  md="5"
                  lg="5"
                >
                  <v-row>
                    <v-col
                      cols="auto"
                      class="font-weight-bold"
                    >
                      {{ getSeasonName(detailIndex + 1, detail.is_yearly) }}
                    </v-col>

                    <v-col
                      v-if="detail.attachments && detail.attachments.length > 0 && !detail.completed_at"
                      cols
                    >
                      <v-btn
                        depressed
                        small
                        color="success"
                        @click="completeForm(detail)"
                      >
                        {{ $t('Complete') }}
                      </v-btn>
                    </v-col>

                    <v-col cols="12">
                      {{ $t('Start Date From') }} {{ $_date_format(detail.from_date) }} - {{ $_date_format(detail.to_date) }}
                    </v-col>

                    <v-col cols="12">
                      {{ $t('Time to fill form') }} {{ $_date_format(detail.start_date) }} - {{ $_date_format(detail.end_date) }}
                    </v-col>

                    <v-col
                      v-if="detail.completed_at"
                      cols="12"
                      class="success--text"
                    >
                      {{ $t('Completed At') }}
                      <div class="dir-ltr d-inline-block">
                        {{ $_date_time_format(detail.completed_at) }}
                      </div>
                      {{ $t('by') }} {{ detail.completed_by }}
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="7"
                  lg="7"
                >
                  <v-row>
                    <v-col cols="12">
                      <div class="d-flex flex-wrap">
                        <input
                          :id="`file-input-${detailIndex}`"
                          type="file"
                          accept=".pdf,.jpg,.jpeg,.png,.xlsx,.xls,.csv,.doc,.docx"
                          style="visibility:hidden; width:0; position:absolute; top:-9999px"
                          @input="attachmentSelected(detail, detailIndex)"
                        >
                        <div
                          style="width: 100px; height: 100px"
                          class="attachment-upload rounded mx-2 mb-4"
                          @click="chooseAttachment(`file-input-${detailIndex}`)"
                        >
                          <div class="attachment-upload-icon">
                            <v-icon class="d-block text-center">
                              mdil-plus
                            </v-icon>
                            <span class="d-block text--secondary text-caption">
                              {{ $t('File') }}
                            </span>
                          </div>
                        </div>

                        <div
                          v-for="(attachment, attachIndex) in detail.attachments"
                          :key="attachIndex"
                          class="attachment-placeholder mx-2 mb-4"
                          style="width: 100px; height: 100px"
                        >
                          <a
                            v-if="$_is_image(attachment.attachment)"
                            class="attachment-container elevation-1 d-block rounded-lg"
                            :href="`${attachment.attachment}?token=${$_auth_token()}`"
                            target="_blank"
                          >
                            <img
                              :src="`${attachment.attachment}?token=${$_auth_token()}`"
                              alt="img"
                              height="100%"
                            >
                          </a>
                          <!--
                      <a
                        v-else
                        :href="`${$_file_path()}${attachment.attachment}?token=${$_auth_token()}`"
                        target="_blank"
                        class="attachment-container d-flex grey rounded-lg justify-center"
                        :class="$vuetify.theme.dark ? 'darken-3': 'lighten-4'"
                      >
                        <v-icon
                          v-if="$_is_pdf(attachment.attachment)"
                          style="font-size: 72px"
                        >
                          far fa-file-pdf
                        </v-icon>

                        <v-icon
                          v-if="$_is_doc(attachment.attachment)"
                          style="font-size: 72px"
                        >
                          far fa-file-word
                        </v-icon>

                        <v-icon
                          v-if="$_is_excel(attachment.attachment)"
                          style="font-size: 72px"
                        >
                          far fa-file-excel
                        </v-icon>
                      </a>

                      <div class="ellipses-text text-center pt-2">
                        <div>{{ attachment.name }}</div>
                        <div class="font-weight-bold text--disabled">
                          {{ attachment?.type?.name }}
                        </div>
                      </div>

                      <v-btn
                        color="info"
                        icon
                        class="edit-btn"
                        :class="[$vuetify.rtl ? 'rounded-tl-0 rounded-bl-0 rounded-tr-0 rounded-br-lg' : 'rounded-tl-0 rounded-bl-0 rounded-tr-0 rounded-br-lg', $vuetify.theme.dark ? 'grey darken-4' : 'white']"
                        @click="editAttachment(attachment)"
                      >
                        <v-icon class="mdil-28px">
                          mdil-pencil
                        </v-icon>
                      </v-btn>

                      <v-btn
                        color="error"
                        icon
                        class="remove-btn"
                        :class="[$vuetify.rtl ? 'rounded-tr rounded-br-0 rounded-tl-0 rounded-bl-lg' : 'rounded-tr rounded-br-0 rounded-tl-0 rounded-bl-lg', $vuetify.theme.dark ? 'grey darken-4' : 'white']"
                        @click="deleteAttachment(attachment.id)"
                      >
                        <v-icon class="mdil-28px">
                          mdil-delete
                        </v-icon>
                      </v-btn> -->
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                  v-if="calcRemainDays(detail.end_date) <= 20"
                  cols="12"
                >
                  <form-fill-progress
                    :is-completed="detail.completed_at ? true: false"
                    :remain-days="calcRemainDays(detail.end_date)"
                  />
                </v-col>

                <v-col
                  v-if="detailIndex != formDetails.length - 1"
                  cols="12"
                >
                  <v-divider />
                </v-col>
              </v-row>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import FormFillProgress from './FormFillProgress.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    FormFillProgress,
  },

  data() {
    return {
      showDetails: false,
      seasonalForms: [],
      activeForm: {},
      formDetails: [],
      skill: 20
    }
  },

  mounted() {
    this.getSeasonalForms()
  },

  methods: {
    ...mapActions([
      'checkCompaniesNotification',
    ]),

    getSeasonalForms() {
      axios.post(`company-seasonal-form/seasonal-forms/`, { company_id: Number(this.$route.params.id) }).then(res => {
        this.seasonalForms = res.data
      })
    },

    openFolder(form) {
      this.activeForm = form
      let payload = { company_seasonal_form_id: Number(form.id) }

      axios.post(`company-seasonal-form-detail/seasonal-forms/`, payload).then(res => {
        this.formDetails = res.data
        this.showDetails = true
      })
    },

    back() {
      this.activeForm = {}
      this.showDetails = false
    },

    getSeasonName(num, isYearly) {
      if (num == 1) return this.$t('First Season')
      if (num == 2) return this.$t('Second Season')
      if (num == 3) return this.$t('Third Season')
      if (num == 4) return this.$t('Fourth Season')
      if (num == 5 && isYearly) return this.$t('Yearly Form')
    },

    calcRemainDays(endDate) {
      let today = Vue.moment(new Date())
      let dueDate = Vue.moment(endDate)
      return Number(dueDate.diff(today, 'days'))
    },

    chooseAttachment(inputId) {
      document.querySelector(`#${inputId}`).click();
    },

    attachmentSelected(detail, detailIndex) {
      let fileInput = document.querySelector(`#file-input-${detailIndex}`)
      let file = fileInput.files[0]
      // get file size in MB
      let fileSize = file.size / 1_000_000
      // if file size greater than 5MB show warning
      if (fileSize > 5) {
        return this.$_notify('File size must be less than 5MB', 'error')
      }

      // store file
      let fd = new FormData()

      fd.append('model_id', detail.id)
      fd.append('model_name', 'CompanySeasonalFormDetail')
      fd.append('attachment', file)
      fd.append('name', file.name)

      axios.post('attachment/', fd).then(() => {
        this.$_notify('Saved successfully')
        this.openFolder(this.activeForm)
      }).finally(() => fileInput.value = '')
    },

    completeForm(detail) {
      this.$_confirm({ type: 'approve' }).then(confirmed => {
        if (confirmed) {
          axios.get(`company-seasonal-form-detail/${detail.id}/complete/`).then(() => {
            this.$_notify(this.$t('Completed successfully'))
            this.openFolder(this.activeForm)
            this.checkCompaniesNotification()
          })
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
</style>